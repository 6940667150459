exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-call-idol-tsx": () => import("./../../../src/pages/call/idol.tsx" /* webpackChunkName: "component---src-pages-call-idol-tsx" */),
  "component---src-pages-call-index-tsx": () => import("./../../../src/pages/call/index.tsx" /* webpackChunkName: "component---src-pages-call-index-tsx" */),
  "component---src-pages-call-template-tsx": () => import("./../../../src/pages/call/template.tsx" /* webpackChunkName: "component---src-pages-call-template-tsx" */),
  "component---src-pages-call-uwaki-tsx": () => import("./../../../src/pages/call/uwaki.tsx" /* webpackChunkName: "component---src-pages-call-uwaki-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-own-goods-tsx": () => import("./../../../src/pages/own_goods.tsx" /* webpackChunkName: "component---src-pages-own-goods-tsx" */)
}

